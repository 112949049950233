html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

[role="button"],
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::placeholder {
  color: #aaadb6;
}

[role="button"] {
  color: inherit;
  cursor: default;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

html {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  background: #f2f3f4;
}

body {
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  color: #222;
}

.container {
  max-width: 1440px;
  margin: auto;
  position: relative;
}

:focus {
  outline: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 700;
  color: #2a2b2c;
}

h1 {
  font-size: 38px;
  line-height: 50px;
  color: #fff;
}

@media (max-width: 699px) {
  h1 {
    font-size: 28px;
    line-height: 34px;
  }
}

h2 {
  font-size: 24px;
  line-height: 32px;
}

@media (max-width: 699px) {
  h2 {
    font-size: 22px;
    line-height: 24px;
  }
}

h3 {
  font-size: 16px;
  line-height: 22px;
}

h4 {
  font-size: 18px;
  line-height: 32px;
}

p {
  margin-top: 0;
  margin-bottom: 25px;
}

p.uppercase {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

a {
  color: #398dd3;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.1s ease-in-out 0s;
}

a:hover {
  color: #1d71b8;
  text-decoration: underline;
}

ul {
  margin-bottom: 25px;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 25px;
  padding-left: 40px;
}

ol li {
  margin-bottom: 15px;
}

ol li:last-of-type {
  margin-bottom: 0;
}

.btn-white {
  border-radius: 3px;
  background-color: #fff;
  color: #398dd3;
  padding: 5px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 16px;
  transition: all 0.2s ease-in-out 0s;
  height: 60px;
  width: 180px;
}

.btn-white:focus {
  outline: none;
}

@media (max-width: 699px) {
  .btn-white {
    height: 52px;
  }
}

.btn-white:hover {
  background: none;
  color: #fff;
  border: 2px solid #fff;
}

@media (max-width: 699px) {
  .btn-white {
    width: 130px;
  }
}

.btn {
  border-radius: 3px;
  background-color: #398dd3;
  color: #fff;
  padding: 5px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 16px;
  transition: all 0.2s ease-in-out 0s;
  height: 52px;
  width: 175px;
}

.btn:focus {
  outline: none;
}

@media (max-width: 699px) {
  .btn {
    height: 52px;
  }
}

.btn:hover {
  background: #1d71b8;
}

.breadcrumbs a {
  color: white;
}

.card {
  background-color: white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.form-section {
  padding-top: 150px;
  padding-bottom: 150px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

@media (max-width: 699px) {
  .form-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.form-section .form-container {
  background: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
  max-width: 920px;
  margin: 0 auto;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.form-section .form-container .tabbertabhide {
  display: none;
}

.form-section .form-container ul.tabbernav {
  margin: 0;
}

.form-section .form-container ul.tabbernav li {
  list-style: none;
  margin: 0;
  display: inline-block;
  width: 50%;
  white-space: nowrap;
  text-align: center;
  color: #2a2b2c;
  box-shadow: inset 0 -1px 0 #e2e2e2;
}

.form-section .form-container ul.tabbernav li a {
  color: #2a2b2c;
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  opacity: 0.7;
}

.form-section .form-container ul.tabbernav li a:hover {
  text-decoration: none;
}

.form-section .form-container ul.tabbernav li:hover {
  box-shadow: inset 0 -3px 0 #398dd3;
  text-decoration: none;
}

.form-section .form-container ul.tabbernav li:hover a {
  opacity: 1;
}

.form-section .form-container ul.tabbernav li.tabberactive {
  box-shadow: inset 0 -3px 0 #398dd3;
}

.form-section .form-container ul.tabbernav li.tabberactive a {
  opacity: 1;
}

.form-section .form-container .form {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 30px;
}

@media (max-width: 699px) {
  .form-section .form-container .form {
    width: 90%;
    margin: 0 auto;
  }
}

.form-section .form-container .form input[type="email"],
.form-section .form-container .form input[type="number"],
.form-section .form-container .form input[type="search"],
.form-section .form-container .form input[type="text"],
.form-section .form-container .form input[type="tel"],
.form-section .form-container .form input[type="url"],
.form-section .form-container .form input[type="password"],
.form-section .form-container .form textarea,
.form-section .form-container .form select {
  height: 40px;
  padding: 6px 10px;
  background-color: #ededef;
  border: 1px solid #ededef;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
}

.form-section .form-container .form input[type="email"],
.form-section .form-container .form input[type="number"],
.form-section .form-container .form input[type="search"],
.form-section .form-container .form input[type="text"],
.form-section .form-container .form input[type="tel"],
.form-section .form-container .form input[type="url"],
.form-section .form-container .form input[type="password"],
.form-section .form-container .form textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-section .form-container .form textarea {
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.form-section .form-container .form input[type="email"]:focus,
.form-section .form-container .form input[type="number"]:focus,
.form-section .form-container .form input[type="search"]:focus,
.form-section .form-container .form input[type="text"]:focus,
.form-section .form-container .form input[type="tel"]:focus,
.form-section .form-container .form input[type="url"]:focus,
.form-section .form-container .form input[type="password"]:focus,
.form-section .form-container .form textarea:focus,
.form-section .form-container .form select:focus {
  background-color: #e8eff8;
  border: 1px solid #e8eff8;
  outline: 0;
}

.form-section .form-container .form label,
.form-section .form-container .form legend {
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
}

.form-section .form-container .form  {
  padding: 0;
  border-width: 0;
}

.form-section .form-container .form input[type="checkbox"],
.form-section .form-container .form input[type="radio"] {
  display: inline;
}

.form-section .form-container .form label > .label-body {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: normal;
}

.form-section .form-container .form button {
  display: block;
  clear: both;
  margin: 0 auto;
  margin-top: 20px;
}

.form-section .form-container .form button:disabled {
  opacity: 0.4
}

.form-section .form-container .form p.guidance {
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
  font-style: italic;
  text-align: left;
  padding: 0;
}

.form-section .form-container h2 {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.form-section .form-container p {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 500px;
  margin: 0 auto;
}

.form-section .form-container img.illustration {
  position: absolute;
  left: -13%;
  bottom: 0;
  width: 240px;
}

@media (max-width: 1199px) {
  .form-section .form-container img.illustration  {
    left: -1%;
    width: 220px;
  }
}

@media (max-width: 959px) {
  .form-section .form-container img.illustration  {
    display: none;
  }
}

.form-section .form-container ul.tabs {
  margin-top: 20px;
  margin-bottom: 10px;
}

.form-section .form-container ul.tabs li {
  display: inline-block;
  width: 50%;
  white-space: nowrap;
  text-align: center;
}

input.search {
  height: 40px;
  padding: 6px 10px;
  background-color: #ededef;
  border: 1px solid #ededef;
  border-radius: 3px;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 5px;
  font-size: 16px;
  font-family: "Nunito", sans-serif;
}

input.search:focus {
  background-color: #e8eff8;
  border: 1px solid #e8eff8;
  outline: 0;
}

.call-to-action {
  border-top: 1px solid #e2e2e2;
  text-align: center;
  background: #fff;
}

.call-to-action .container {
  padding-top: 120px;
  padding-bottom: 120px;
  max-width: 1700px;
  margin: 0 auto;
  background: #fff;
  background-image: url(./assets/images/call-to-action/colinked-cta-corporate-icons.png),
    url(./assets/images/call-to-action/colinked-cta-founder-icons.png);
  background-position: left, right;
  background-repeat: no-repeat, no-repeat;
  background-size: 377px 381px;
}

@media (max-width: 1199px) {
  .call-to-action .container {
    padding-top: 90px;
    padding-bottom: 90px;
    background-size: 267px 270px;
    background-position: left, right;
  }
}

@media (max-width: 959px) {
  .call-to-action .container {
    padding-top: 60px;
    padding-bottom: 360px;
    background-image: url(./assets/images/call-to-action/colinked-cta-all-icons.png);
    background-position: bottom;
    background-size: 683px 336px;
  }
}

.call-to-action .content {
  max-width: 504px;
  margin: 0 auto;
}

.contact-call-to-action {
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
  clear: both;
  padding-top: 50px;
  padding-bottom: 50px;
  clear: both;
}

@media (max-width: 959px) {
  .contact-call-to-action {
    width: 90%;
  }
}




footer {
  min-height: 320px;
  /* background: #4b4c4f; */
}

.page-header-centered {
  text-align: center;
  background: linear-gradient(358.41deg, #398cd3 0%, #225bbe 100%);
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 699px) {
  .page-header-centered {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.page-header-centered h1 {
  color: #fff;
  margin: 0 auto;
  max-width: 700px;
  font-weight: 200;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 699px) {
  .page-header-centered h1 {
    max-width: 90%;
  }
}

.page-header-centered h1 span {
  font-weight: 700;
}

.page-header-centered p {
  color: #fff;
  margin: 0 auto;
  max-width: 700px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 699px) {
  .page-header-centered p {
    max-width: 90%;
  }
}

.page-header-aligned-left {
  background: linear-gradient(358.41deg, #398cd3 0%, #225bbe 100%);
  padding-top: 50px;
  padding-bottom: 50px;
  color: #fff;
}

.page-header-aligned-left h1 {
  padding-left: 3%;
  margin-bottom: 0;
  font-weight: 200;
}

.page-header-aligned-left h1 span {
  font-weight: 700;
}

.content-padding-large {
  padding-top: 100px;
  padding-bottom: 100px;
  overflow: hidden;
}

@media (max-width: 1399px) {
  .content-padding-large {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (max-width: 699px) {
  .content-padding-large {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.content-padding-small {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;
}

@media (max-width: 699px) {
  .content-padding-small {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.content-padding-small .content {
  max-width: 700px;
  margin: 0 auto;
}

.content-padding-small .content ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 25px;
  padding-left: 40px;
}

.content-padding-small .content ul li {
  margin-bottom: 15px;
}

.content-padding-small .content ul li:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 699px) {
  .content-padding-small .content {
    width: 90%;
  }
}

.logo-grid, .corp-logos {
  padding-top: 100px;
  padding-bottom: 70px;
  background: #fff;
  border-top: 1px solid #e2e2e2;
  text-align: center;
  overflow: hidden;
}

.logo-grid .grid-items {
  max-width: 1400px;
  margin: 0 auto;
}

.logo-grid img {
  float: left;
  width: 25%;
  margin-bottom: 45px;
}

.corp-logos img {
  max-width: 1024px;
  width: 90vw;
  margin: 0 auto;
}

@media (max-width: 959px) {
  .logo-grid img {
    width: 50%;
  }
}

.homepage-hero-banner {
  background-image: linear-gradient(
      0deg,
      #225bbe,
      rgba(41, 106, 196, 0.9),
      rgba(43, 110, 198, 0.8),
      rgba(50, 125, 204, 0.7),
      rgba(57, 140, 211, 0.7)
    ),
    url(./assets/images/homepage/colinked-homepage-background-hero-image.jpg);
  background-position: 10% center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 150px;
  padding-bottom: 150px;
}

@media (max-width: 699px) {
  .homepage-hero-banner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.homepage-hero-banner .content .mission-statement {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 699px) {
  .homepage-hero-banner .content .mission-statement {
    width: 90%;
  }
}

.homepage-hero-banner .content .mission-statement h1 {
  font-weight: 200;
}

.homepage-hero-banner .content .mission-statement p {
  color: #fff;
  font-size: 18px;
  line-height: 26px;
}

@media (max-width: 699px) {
  .homepage-hero-banner .content .mission-statement p {
    font-size: 16px;
  }
}

.homepage-hero-banner .content .mission-statement span {
  font-weight: 700;
}

.homepage-hero-banner .content .mission-statement button {
  margin-right: 20px;
}

@media (max-width: 699px) {
  .homepage-hero-banner .content .mission-statement button {
    margin-right: 10px;
  }
}

.about-panel {
  background: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  margin-top: 110px;
  margin-bottom: 100px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
@media (max-width: 959px) {
  .about-panel {
    margin-top: 220px;
  }
}
@media (max-width: 699px) {
  .about-panel {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.about-panel img {
  position: absolute;
  top: -52px;
  bottom: 0;
  left: 10px;
  width: 170px;
}
@media (max-width: 1199px) {
  .about-panel img {
    top: -22px;
    left: 5px;
    width: 150px;
  }
}
@media (max-width: 959px) {
  .about-panel img {
    display: none;
  }
}
.about-panel .content {
  max-width: 640px;
  margin: 0 auto;
}
@media (max-width: 959px) {
  .about-panel .content {
    padding: 20px 25px 5px 25px;
  }
}
.about-panel.investor-about {
  margin-bottom: 20px;
}
.about-panel.investor-about p {
  margin-bottom: 0;
}
.about-panel.investor-about img {
  top: 30px;
  left: -20px;
}

.homepage-about-panel {
  background: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 1020px;
  margin: 0 auto;
  position: relative;
  margin-top: 110px;
  margin-bottom: 100px;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 959px) {
  .homepage-about-panel {
    margin-top: 220px;
  }
}

@media (max-width: 699px) {
  .homepage-about-panel {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.homepage-about-panel img {
  position: absolute;
  top: -52px;
  bottom: 0;
  left: 10px;
  width: 170px;
}

@media (max-width: 1199px) {
  .homepage-about-panel img {
    top: -22px;
    left: 5px;
    width: 150px;
  }
}

@media (max-width: 959px) {
  .homepage-about-panel img {
    display: none;
  }
}

.homepage-about-panel .content {
  max-width: 640px;
  margin: 0 auto;
}

@media (max-width: 959px) {
  .homepage-about-panel .content {
    padding: 20px 25px 5px 25px;
  }
}

.homepage-customers {
  background: #fff;
  padding-top: 150px;
  padding-bottom: 150px;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}

@media (max-width: 959px) {
  .homepage-customers {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.homepage-customers .customer-block:first-of-type {
  margin-bottom: 150px;
}

@media (max-width: 959px) {
  .homepage-customers .customer-block:first-of-type {
    margin-bottom: 60px;
  }
}

.homepage-customers .customer-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
}

@media (max-width: 959px) {
  .homepage-customers .customer-block {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    flex-direction: column;
  }
  .homepage-customers .customer-block .btn {
    margin-bottom: 35px;
  }
  .homepage-customers .customer-block.reverse {
    flex-direction: column-reverse;
  }
}

.homepage-customers .customer-block .left {
  margin-right: 5%;
}

@media (max-width: 959px) {
  .homepage-customers .customer-block .left {
    margin-right: 0;
  }
}

.homepage-customers .customer-block .text {
  flex: 1 1 0;
}

@media (max-width: 959px) {
  .homepage-customers .customer-block .text {
    width: 100%;
    flex: none;
  }
}

.homepage-customers .customer-block .text ul {
  max-width: 480px;
}

.homepage-customers .customer-block .text ul li {
  margin: 0;
  padding: 36px 0 36px 84px;
  list-style: none;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 58px;
}

.homepage-customers .customer-block .text ul li.post {
  background-image: url("./assets/images/homepage/colinked-homepage-icon-post.png");
}

.homepage-customers .customer-block .text ul li.find {
  background-image: url("./assets/images/homepage/colinked-homepage-icon-search.png");
}

.homepage-customers .customer-block .text ul li.engage {
  background-image: url("./assets/images/homepage/colinked-homepage-icon-engage.png");
}

.homepage-customers .customer-block .text ul li.discover {
  background-image: url("./assets/images/homepage/colinked-homepage-icon-discover.png");
}

.homepage-customers .customer-block .text ul li.reach {
  background-image: url("./assets/images/homepage/colinked-homepage-icon-reach.png");
}

.homepage-customers .customer-block .text ul li.invest {
  background-image: url("./assets/images/homepage/colinked-homepage-icon-invest.png");
}

.homepage-customers .customer-block .image {
  flex: 1 1 0;
}

@media (max-width: 959px) {
  .homepage-customers .customer-block .image {
    text-align: center;
    width: 100%;
    flex: none;
  }
}

.homepage-customers .customer-block .image img {
  width: 100%;
}

@media (max-width: 959px) {
  .homepage-customers .customer-block .image img {
    max-width: 340px;
    margin: 0 auto;
  }
}

.programs {
  padding-top: 150px;
  padding-bottom: 150px;
  border-bottom: 1px solid #e2e2e2;
}

.programs h2 {
  text-align: center;
}

.programs p.all-link {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 0;
}

@media (max-width: 699px) {
  .programs {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.featured-programs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1850px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 30px;
}

@media (max-width: 699px) {
  .featured-programs {
    flex-direction: column;
  }
}

.featured-programs div.card {
  background: #fff;
  width: 24%;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-weight: 400;
  color: #2a2b2c;
}

@media (max-width: 959px) {
  .featured-programs div.card {
    width: 49%;
    margin-bottom: 20px;
  }
  .featured-programs div.card:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }
}

@media (max-width: 699px) {
  .featured-programs div.card {
    width: 100%;
    flex: none;
    margin-bottom: 20px;
  }
  .featured-programs div.card:nth-last-of-type(-n + 2) {
    margin-bottom: 20px;
  }
  .featured-programs div.card:last-child {
    margin-bottom: 0;
  }
}

.featured-programs div.card:hover {
  text-decoration: none;
}

.featured-programs div.card:hover h3 {
  text-decoration: underline;
}

.featured-programs div.card .card-header {
  position: relative;
}

.featured-programs div.card .card-header img {
  width: 100%;
  font-size: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.featured-programs div.card .card-header .overlay {
  position: absolute;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.3)
  );
  width: 100%;
  height: 100%;
  top: -8px;
}

.featured-programs div.card .card-header .company-logo {
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 2;
  bottom: -30px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  margin: auto;
}

.featured-programs div.card .card-content {
  box-sizing: border-box;
  padding: 60px 20px 40px 20px;
}

.featured-programs div.card .card-content h3 {
  margin-bottom: 10px;
  font-weight: 700;
  color: #398dd3;
}

.featured-programs div.card .card-content p {
  margin-bottom: 20px;
  margin-bottom: 10px;
}

.featured-programs div.card .card-content ul {
  margin-bottom: 0;
}

.featured-programs div.card .card-content ul li {
  margin-bottom: 10px;
}

.featured-programs div.card .card-content ul li:last-child {
  margin-bottom: 0;
}

.featured-programs div.card .card-content ul li span {
  display: inline-block;
  vertical-align: top;
  margin-right: 3px;
}

.featured-programs div.card .card-footer {
  display: flex;
  align-items: center;
  margin-top: auto;
  box-sizing: border-box;
  padding: 15px 20px 15px 20px;
  border-top: 1px solid #f2f3f4;
  text-align: left;
  overflow: hidden;
}

.featured-programs div.card .tag {
  padding: 5px 10px 5px 10px;
  font-weight: 700;
  background: #f5eddc;
  color: #cea751;
  border-radius: 50px;
  font-size: 13px;
}

.featured-programs div.card .tag.indicator {
  background-color: rgba(255, 255, 255, 0.95);
  color: #2a2b2c;
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.featured-programs div.card .tag.indicator .dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #85c97c;
  margin-right: 5px;
}

.about-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px 0 20px;
  box-sizing: border-box;
}

.about-block:first-of-type {
  margin-bottom: 80px;
}

@media (max-width: 699px) {
  .about-block:first-of-type {
    margin-bottom: 40px;
  }
}

@media (max-width: 699px) {
  .about-block {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    flex-direction: column;
  }
}

.about-block .image {
  flex: 1 1 0;
  padding-right: 5%;
}

@media (max-width: 699px) {
  .about-block .image {
    text-align: center;
    width: 100%;
    flex: none;
    padding-right: 0;
  }
}

.about-block .image img {
  width: 100%;
}

@media (max-width: 699px) {
  .about-block .image img {
    margin: 0 auto;
    margin-bottom: 25px;
  }
}

.about-block .text {
  flex: 1 1 0;
}

@media (max-width: 699px) {
  .about-block .text {
    width: 100%;
    flex: none;
  }
}

.benefit {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
}

@media (max-width: 699px) {
  .benefit {
    width: 90%;
    margin: 0 auto;
    padding: 0;
    flex-direction: column;
  }
  .benefit.reverse {
    flex-direction: column-reverse;
  }
}

.benefit .left {
  padding-right: 5%;
}

.benefit .text {
  flex: 1 1 0;
}

@media (max-width: 699px) {
  .benefit .text {
    width: 100%;
    flex: none;
  }
}

.benefit .image {
  flex: 1 1 0;
}

@media (max-width: 699px) {
  .benefit .image {
    text-align: center;
    width: 100%;
    flex: none;
  }
}

.benefit .image img {
  width: 100%;
  max-width: 520px;
}

@media (max-width: 699px) {
  .benefit .image img {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 25px;
  }
}


.faqcontent ul {
  list-style-type: disc;
  margin-left: 1.25em;
  margin-top: 0.25em;
}

.faqs {
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.faqs ul.filter {
  float: right;
}

@media (max-width: 699px) {
  .faqs ul.filter {
    float: left;
    margin-bottom: 0;
  }
}

.faqs ul.filter li {
  display: inline-block;
  margin-left: 20px;
  transition: all 0.1s ease-in-out 0s;
}

@media (max-width: 699px) {
  .faqs ul.filter li {
    margin-left: 0;
    margin-bottom: 12px;
    width: 100%;
  }
}

.faqs ul.filter li button {
  font-weight: 700;
  opacity: 0.6;
}

.faqs ul.filter li button.is-active {
  opacity: 1;
}

.faqs ul.filter li button:hover {
  opacity: 1;
  cursor: pointer;
}

.faqs ul.filter li button:focus {
  outline: none;
}

.faqs .accordionItem {
  float: left;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  margin-bottom: 25px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.faqs .accordionItem h3 {
  margin-bottom: 0;
}

.faqs .accordionItem .accordionItemHeading {
  padding: 30px 30px 30px 30px;
}

@media (max-width: 699px) {
  .faqs .accordionItem .accordionItemHeading {
    padding: 20px 30px 20px 15px;
  }
}

.faqs .accordionItem .accordionItemHeading:hover {
  cursor: pointer;
}

.faqs .accordionItem .accordionItemHeading .left {
  width: 92%;
  padding-right: 3%;
  display: inline-block;
  vertical-align: middle;
}

.faqs .accordionItem .accordionItemHeading .right {
  width: 2%;
  display: inline-block;
  vertical-align: middle;
}

.faqs .accordionItem.open .right img {
  transform: rotate(180deg);
}

.faqs .accordionItem.close .accordionItemContent {
  height: 0;
  transition: height 1s ease-out;
  -webkit-transform: scaleY(0);
  -o-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  float: left;
  display: block;
}

.faqs .accordionItem.open .accordionItemContent {
  padding: 0 30px 5px 30px;
  width: 100%;
  margin: 0 0 10px 0;
  display: block;
  -webkit-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transform-origin: top;
  -o-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top;
  -webkit-transition: -webkit-transform 0.4s ease-out;
  -o-transition: -o-transform 0.4s ease;
  -ms-transition: -ms-transform 0.4s ease;
  transition: transform 0.4s ease;
  box-sizing: border-box;
}

@media (max-width: 699px) {
  .faqs .accordionItem.open .accordionItemContent {
    padding: 0 17px 5px 15px;
    margin: 0;
  }
}

.faqs .accordionItem.open .accordionItemHeading {
  margin: 0;
}

.content-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: linear-gradient(
      0deg,
      #225bbe,
      #296ac4,
      rgba(43, 110, 198, 0.9),
      rgba(50, 125, 204, 0.8),
      rgba(57, 140, 211, 0.7)
    ),
    url(./assets/images/landing-page/colinked-people.jpg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-wrap .content {
  max-width: 800px;
  text-align: center;
}

@media (max-width: 959px) {
  .content-wrap .content {
    max-width: 90%;
  }
}

.content-wrap .content img.logo {
  width: 310px;
  margin-bottom: 40px;
}

@media (max-width: 959px) {
  .content-wrap .content img.logo {
    width: 220px;
    margin-bottom: 18px;
  }
}

.content-wrap .content p.uppercase {
  font-size: 18px;
}

@media (max-width: 959px) {
  .content-wrap .content p.uppercase {
    font-size: 14px;
  }
}

.content-wrap .content h1 {
  font-weight: 200;
  font-size: 42px;
  line-height: 55px;
}

@media (max-width: 959px) {
  .content-wrap .content h1 {
    font-size: 25px;
    line-height: 35px;
  }
}

.content-wrap .content p {
  color: #fff;
}

.content-wrap .content p.bottom {
  margin-bottom: 0;
}

.content-wrap .content p a {
  color: #fff;
  text-decoration: underline;
}

.content-wrap .content p a:hover {
  text-decoration: none;
  color: #fff;
}

.content-wrap .content ul.social-media {
  margin-bottom: 40px;
}

.content-wrap .content ul.social-media li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 959px) {
  .content-wrap .content ul.social-media li {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.content-wrap .content ul.social-media li a {
  opacity: 0.8;
  transition: all 0.2s ease-in-out 0s;
}

.content-wrap .content ul.social-media li a:hover {
  opacity: 1;
}

.content-wrap .content ul.social-media li img {
  width: 60px;
}

@media (max-width: 959px) {
  .content-wrap .content ul.social-media li img {
    width: 44px;
  }
}

.body1 {
  color: #2a2b2c;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}

.errorPanel {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #f9efed;
  border: 1px solid #db6e53;
  border-radius: 3px;
  color: #2a2b2c;
  font-size: 14px;
  min-height: 40px;
  margin: 10px auto;
  padding: 10px 15px;
}

.errorPanelValidation {
  box-sizing: border-box;
  background-color: #f9efed;
  border: 1px solid #db6e53;
  border-radius: 3px;
  color: #2a2b2c;
  font-size: 14px;
  min-height: 40px;
  margin: 10px auto;
  padding: 10px 15px;
  white-space: pre;
}

.errorPanelIcon {
  cursor: pointer;
  margin-left: 10;
}

.helperText {
  color: #aaadb6;
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  /* outline: 0.5px dashed red; */
}

.placeholder {
  border: 1px solid grey;
  margin-top: 32px;
  margin-bottom: 16px;
}

.placeholder > div {
  padding: 10px 20px;
}

@media only screen and (min-width: 1500px) {
  .body {
    width: 1260px;
    margin: 0 auto;
  }
  .body-container {
    width: 1260px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1260px) and (max-width: 1499px) {
  .body {
    width: 1260px;
    margin: 0 auto;
  }
  .body-container {
    width: 1260px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1259px) {
  .body {
    width: 960px;
    margin: 0 auto;
  }
  .body-container {
    width: 960px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 640px) and (max-width: 959px) {
  .body, .body-container {
    width: 90vw;
    min-width: 640px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 639px) {
  .body {
    width: 90vw;
    margin: 0 auto;
  }
  .body-container {
    width: 90vw;
    margin: 0 auto;
  }
}

/* editor view */

.DraftEditor-editorContainer {
  border: none;
}

.public-DraftEditor-content {
  background-color: #ededef;
  min-height: 100px;
}

.public-DraftEditor-content:focus {
  background-color: #e8eff8;
}

.public-DraftEditor-content * {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #2a2b2c;
}

/* details view */

.rich-text-content * {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #2a2b2c;
}

.public-DraftEditor-content h1,
.rich-text-content h1,
.public-DraftEditor-content h2,
.rich-text-content h2,
.public-DraftEditor-content h3,
.rich-text-content h3,
.public-DraftEditor-content h4,
.rich-text-content h4 {
  font-family: "Nunito", sans-serif;
  color: #2a2b2c;
  padding-top: 0;
  font-weight: 700;
}

.public-DraftEditor-content h1 span,
.rich-text-content h1 {
  font-size: 22px;
  margin-top: 24px;
}
.rich-text-content h1:first-child {
  margin-top: 0px;
}

.public-DraftEditor-content h2 span,
.rich-text-content h2 {
  font-size: 18px;
  margin-top: 24px;
}

.public-DraftEditor-content h3 span,
.rich-text-content h3 {
  margin-top: 20px;
}

.public-DraftEditor-content a,
.rich-text-content a {
  text-decoration: underline;
  color: #6592cc;
}

.public-DraftEditor-content blockquote,
.rich-text-content blockquote,
.fuse-rte-blockquote {
  /* font-family: Hoefler Text,Georgia,serif; */
  /* font-style: italic; */
  font-weight: 500;
  color: #2a2b2c;
  border-left: 5px solid #398dd3;
  margin: 15px 0;
  padding: 0px 15px;
}

.rich-text-content blockquote,
.fuse-rte-blockquote * {
  color: #2a2b2c;
}

.rich-text-content ol {
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  margin: 1em 0;
  -webkit-padding-start: 24px;
  list-style-type: decimal;
}

.rich-text-content ol li,
.rich-text-content ul li,
.public-DraftEditor-content ol li,
.public-DraftEditor-content ul li  {
  margin-bottom: 5px;
}

.rich-text-content ul {
  -webkit-padding-start: 24px;
  list-style-type: disc;
}

.rich-text-content > *:last-child {
  margin-bottom: 0;
}

.public-DraftEditor-content ol > li:before,
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -32px;
  /* position: absolute; */
  /* text-align: right; */
  /* width: 30px; */
}

#root
  > div
  > div.FormBuilder-root-4
  > div.FormBuilderForm-root-106
  > div.FormBuilderForm-sectionContainer-109
  > div
  > div.Heading-root-182
  > div.RichTextEditor__root___2QXK-.Heading-rteRoot-185
  > div.RichTextEditor__editor___1QqIU
  > div
  > div
  > div
  .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  text-align: center;
  color: white;
}

.App-title {
  font-size: 1.5em;
  text-align: center;
}

.App-intro {
  font-size: large;
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
