.react-datepicker__time > div > ul {
  padding-left: 0;
  padding-right: 0;
}

div.react-datepicker-wrapper > div > input {
  /* border: 1px solid #ced4da; */
  box-sizing: border-box;
  border: none;
  color: #2a2b2c;
  padding: 10px 15px;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  min-height: 40px;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 3px;
  background-color: #ededef;
}

div.react-datepicker-wrapper > div > input:focus {
  background-color: #e8eff8;
  outline: none;
}

div.react-datepicker-wrapper {
  width: 100%;
}

div.react-datepicker-wrapper > div {
  width: 100%;
}

div.react-datepicker-wrapper > div > input {
  width: 100%;
}

button.react-datepicker__navigation.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
  height: 10px;
  color: transparent;
}
button.react-datepicker__navigation.react-datepicker__navigation--next {
  border-left-color: #ccc;
  height: 10px;
  color: transparent;
}
